import styled from 'styled-components'
import { motion } from 'framer-motion'
import { Eyebrow, defaultTheme } from '../../styles'

export const PageBannerElem = styled(motion.div)`
  height: 76vh;
  min-height: 460px;
  background: ${defaultTheme.darkBlue};
  color: ${defaultTheme.white};

  ${Eyebrow} {
    max-width: 70%;

    @media(min-width: 900px) {
      max-width: 100%;
    }
  }
`;

export const PracticeAreaTitle = styled(motion.h2)`
    margin: 12px auto 0;
    max-width: 90%;
    font-size: 34px;
    font-weight: 700;
    letter-spacing: 0.031em;
    line-height: 1.25;
    text-align: center;

    @media(min-width: 360px) {
        font-size: 42px;
    }

    @media(min-width: 750px) {
        font-size: 72px;
    }
`