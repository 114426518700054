import React from 'react'
import { Container, Eyebrow } from '../../styles'
import {
    PageBannerElem,
    PracticeAreaTitle
} from './PageBannerStyles'

const transition = {
    duration: 0.25,
    ease: [0.43, 0.13, 0.23, 0.96]
}

const PageBanner = ({ title, subtitle }) => {
    return (
        <PageBannerElem
            animate={{
                height: "60vh",
                transition: { ...transition, duration: 0.5}
            }}
        >
            <Container flex fullHeight column justifyCenter alignCenter>
                <Eyebrow
                    centered
                    small
                    initial={{opacity: 0, y: 20 }}
                    animate={{
                        opacity: 1,
                        y: 0,
                        transition: { delay: 0.25, ...transition, duration: 0.5}
                    }}
                >
                    {subtitle}
                </Eyebrow>
                <PracticeAreaTitle
                    initial={{ opacity: 0, y: 30 }}
                    animate={{
                        opacity: 1,
                        y: 0,
                        transition: { delay: 0.45, ...transition, duration: 0.5}
                    }}
                >
                    {title}
                </PracticeAreaTitle>
            </Container>
        </PageBannerElem>
    )
}

export default PageBanner
